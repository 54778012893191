/*
  Here will be shared events for all components used withing tabs layout.
  Each method emits shared event to the core class Content.vue.

  Each method must accept a context argument ('this' instance), indicating the component or instance from which it is being called. This is needed
  because all the components are children of the Content component and by providing context we can call $emit event
*/

// Save tab data when user clicks on save button
export function saveTab (context) {
  if (context && typeof context.$emit === 'function') {
    context.$emit('save-tab')
  }
  else {
    console.error('Invalid context provided to saveTab. $emit is not available.')
  }
}

// Cancel tab save data when user clicks on cancel button
export function cancelTabSave (context) {
  if (context && typeof context.$emit === 'function') {
    context.$emit('cancel-tab-save')
  }
  else {
    console.error('Invalid context provided to saveTab. $emit is not available.')
  }
}

// Manage edit variable with provided value
export function setEdit (context, value) {
  if (context && typeof context.$emit === 'function') {
    context.$emit('set-edit', value)
  }
  else {
    console.error('Invalid context provided to saveTab. $emit is not available.')
  }
}

// Clear validation errors by provided validation error key
export function clearValidationErrors (context, key) {
  if (context && typeof context.$emit === 'function') {
    context.$emit('clear-validation-errors', key)
  }
  else {
    console.error('Invalid context provided to saveTab. $emit is not available.')
  }
}
