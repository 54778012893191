<template>
  <div>
    <div>
      <span
        id="text-field-label"
      >
        {{ dynamic ? ($vuetify.breakpoint.mobile && field && field.label ? field.label : '' ) : (field && field.label ? field.label : '') }}
      </span>
      <v-tooltip
        v-if="field && field.tooltip"
        top
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-icon
            class="ml-2"
            :style="field.tooltip.style ? field.tooltip.style :  getDefaultIconTooltipStyle()"
            v-bind="attrs"
            v-on="on"
          >
            {{ getTooltipIcon() }}
          </v-icon>
        </template>
        <span>
          {{ getTooltipText() }}
        </span>
      </v-tooltip>
    </div>
    <div
      :style="{ cursor: field && !field.editable ? 'not-allowed' : 'default' }"
    >
      <v-text-field
        ref="input"
        :class="disabled ? 'mt-1 custom-text-field' : 'mt-1'"
        v-model="textValue"
        :type="getFieldType()"
        dense
        flat
        outlined
        :autofocus="autofocus"
        hide-details="auto"
        :placeholder="getPlaceholder()"
        :disabled="disabled"
        :error-messages="getValidationErrors()"
        :error-count="getValidationErrors().length || computedRules.length"
        :append-icon="getFieldAppendIcon()"
        :rules="computedRules"
        :append-outer-icon="getFieldAppendOuterIcon()"
        :style="{ backgroundColor: field && field.editable !== undefined && !field.editable ? '#E5E7EB' : '' }"
        @input="onInput"
        @click="event => $emit('click', event)"
        @click:append="onClickAppend"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

const DEFAULT_FIELD_TYPE = 'text'
const DEFAULT_PLACEHOLDER_VALUE = ''
const DEFAULT_TOOLTIP_COLOR = '#6B7280'
const DEFAULT_TOOLTIP_ICON = 'mdi-information-slab-circle'
const DEFAULT_TOOLTIP_TEXT = ''
const DEFAULT_TOOLTIP_FONT_SIZE = '16px'
const DEFAULT_TOOLTIP_MARGIN_BOTTOM = '1px'
const DEFAULT_ON_INPUT_TIMEOUT = 500
export default {
  name: 'TextField',

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({})
    },
    value: {
      type: [Number, String, Object, Array],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validationErrors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    collectionKey: {
      type: String,
      default: ''
    },
    collectionIndex: {
      type: Number,
      default: 0
    },
    dynamic: {
      type: Boolean,
      default: false
    },
    updateData: {
      type: Function,
      default: () => {}
    },
    updateValidationErrors: {
      type: Function,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'clear-validation-errors',
    'input',
    'click',
    'append-click'
  ],

  computed: {
    textValue: {
      get () {
        const { custom_display_value: customDisplayValue } = this.field?.options || {}

        return customDisplayValue && typeof customDisplayValue === 'function' ? customDisplayValue(this.value) : this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    },

    computedRules () {
      let rules = []

      if (this.rules.length) {
        rules = [...this.rules]
      }

      if (rules.includes('email')) {
        const emailPattern = value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Upišite ispravnu email adresu'
        }
        rules = rules.map(rule => (rule === 'email' ? emailPattern : rule))
      }

      if (rules.includes('required')) {
        const requiredPattern = value => !!value || 'Ovo polje je obavezno'
        rules = rules.map(rule => (rule === 'required' ? requiredPattern : rule))
      }

      return rules
    },

    hasValidationErrors () {
      const errors = this.getValidationErrors()
      const rules = this.computedRules

      const hasErrors = errors.length > 0
      const hasUnsatisfiedRules = rules.some(rule => {
        return typeof rule === 'function' && rule(this.textValue) !== true
      })

      return hasErrors || hasUnsatisfiedRules
    }
  },

  data () {
    return {
      showPassword: false,
      onInputTimeoutId: null
    }
  },

  watch: {
    textValue: {
      handler (value) {
        this.$emit('clear-validation-errors')
        clearTimeout(this.onInputTimeoutId)

        if (this.field && this.field.onInput && typeof this.field.onInput === 'function') {
          this.onInputTimeoutId = setTimeout(() => {
            this.field.onInput(value, {
              updateData: (val) => this.updateData(val),
              updateValidationErrors: (val) => this.updateValidationErrors(val),
              formData: this.formData
            })
          }, DEFAULT_ON_INPUT_TIMEOUT)
        }
      }
    }
  },
  methods: {
    isEmpty,

    onInput () {
      this.$emit('clear-validation-errors')
    },

    getValidationErrors () {
      let messages = []

      if (isEmpty(this.validationErrors) || !this.field || !this.field.key) {
        return messages
      }

      for (const errorKey in this.validationErrors) {
        if (errorKey === this.field.key) {
          messages = this.validationErrors[errorKey]
        }
        else {
          const formattedCollectionKey = this.collectionKey + '.' + this.collectionIndex + '.' + this.field.key
          if (errorKey === formattedCollectionKey) {
            messages = this.validationErrors[errorKey]
          }
        }
      }

      return messages
    },

    getPlaceholder () {
      return this.field && this.field.placeholder && typeof this.field.placeholder === 'string' ? this.field.placeholder : DEFAULT_PLACEHOLDER_VALUE
    },

    getFieldType () {
      const { field_type: fieldType } = this.field || {}

      if (!fieldType || typeof fieldType !== 'string') {
        return DEFAULT_FIELD_TYPE
      }

      if (fieldType === 'password') {
        return this.showPassword ? DEFAULT_FIELD_TYPE : 'password'
      }

      return this.field && this.field.field_type && typeof this.field.field_type === 'string' ? this.field.field_type : DEFAULT_FIELD_TYPE
    },

    getTooltipText () {
      return this.field && this.field.tooltip && this.field.tooltip.text ? this.field.tooltip.text : DEFAULT_TOOLTIP_TEXT
    },

    getTooltipIcon () {
      return this.field && this.field.tooltip && this.field.tooltip.icon ? this.field.tooltip.icon : DEFAULT_TOOLTIP_ICON
    },

    getDefaultIconTooltipStyle () {
      return {
        color: DEFAULT_TOOLTIP_COLOR,
        fontSize: DEFAULT_TOOLTIP_FONT_SIZE,
        marginBottom: DEFAULT_TOOLTIP_MARGIN_BOTTOM
      }
    },

    focus () {
      this.$nextTick(() => {
        const element = this.$refs.input.$el.querySelector('input')
        if (element) {
          setTimeout(() => {
            element.focus()
          }, 100)
        }
      })
    },

    getFieldAppendIcon () {
      const { appendIcon, field_type: fieldType } = this.field || {}

      if (appendIcon && typeof appendIcon === 'string') {
        return appendIcon
      }
      else if (fieldType === 'password') {
        return this.showPassword ? 'mdi-eye' : 'mdi-eye-off'
      }

      return ''
    },

    getFieldAppendOuterIcon () {
      const { appendOuterIcon } = this.field || ''

      if (appendOuterIcon && typeof appendOuterIcon === 'string') {
        return appendOuterIcon
      }

      return ''
    },

    onClickAppend () {
      this.$emit('append-click')
      this.showPassword = !this.showPassword
    }
  }
}
</script>

<style scoped>
::v-deep.v-input > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #111827 !important;
  font-weight: 500 !important;
}
::v-deep.v-input--is-disabled > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #9CA3AF !important;
  font-weight: 400 !important;
}

::v-deep.v-input > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon--prepend-inner > .v-icon {
  color: rgba(0, 0, 0, 0.38) !important;
}

::v-deep.v-input > .v-input__append-outer > .v-input__icon > .v-icon {
  color: #111827 !important;
  font-size: 15px !important;
}

::v-deep {
  .v-input--is-disabled > .v-input__control > .v-input__slot > .v-text-field__slot > input {
    font-family: 'Satoshi', sans-serif;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #9CA3AF !important;
    font-weight: 400 !important;
  }

  .v-input > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon > .v-icon {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .v-input > .v-input__append-outer > .v-input__icon > .v-icon {
    color: #111827 !important;
    font-size: 15px !important;
  }

  .v-text-field--outlined fieldset {
    border-color: #E5E7EB !important;
    border-radius: 8px !important;
  }
  .custom-text-field input[type='number'] {
    -moz-appearance:textfield;
  }
  .custom-text-field input::-webkit-outer-spin-button,
  .custom-text-field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

#text-field-label {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
  color: #111827;
  line-height: 20px;
  font-weight: 500;
}
</style>
